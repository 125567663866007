<template>
	<div class="page">
		<div class="shouyinL">
			<userPanel></userPanel>
		</div>
		<div class="shouyinR">
			<div style="width:100%;display: flex;">
				<div class="shouyinRBox1">
					<div class="box1Item" :class="module==='消费'?'active':''" @click="changeModule('消费')" >
						<img class="box1Img" v-if="module==='消费'" src="../../../assets/img/shouyin/nav1.png" />
						<img class="box1Img" v-else src="../../../assets/img/shouyin/nav1_1.png" />
						<div class="box1Text">消费</div>
					</div>
					<div class="box1Item" :class="module==='abc'?'active':''"  @click="changeModule('abc')" v-if="$buttonAuthority('mendian-shouyin-Searchcode')"> 
						<img class="box1Img" v-if="module==='abc'" src="../../../assets/img/shouyin/nav2.png"/>
						<img class="box1Img" v-else src="../../../assets/img/shouyin/nav2_1.png" />
						<div class="box1Text">查询条码</div>
					</div>
					<div class="box1Item" :class="module==='活动'?'active':''"  @click="changeModule('活动')" v-if="$buttonAuthority('mendian-shouyin-SearchActivity')">
						<img class="box1Img" v-if="module==='活动'" src="../../../assets/img/shouyin/nav3.png"/>
						<img class="box1Img" v-else src="../../../assets/img/shouyin/nav3_1.png" />
						<div class="box1Text">查询活动</div>
					</div>
					<div class="box1Item" :class="module==='def'?'active':''" @click="changeModule('def')" v-if="$buttonAuthority('mendian-shouyin-rechargeUser')">
						<img class="box1Img" v-if="module==='def'" src="../../../assets/img/shouyin/nav4.png" @click="changeModule('def')"/>
						<img class="box1Img" v-else src="../../../assets/img/shouyin/nav4_1.png" />
						<div class="box1Text">会员充值</div>
					</div>
				</div>
				<div class="shouyinRBox2">
					<div class="box2Item" :class="module==='今日'?'active':''" @click="changeModule('今日')" v-if="$buttonAuthority('mendian-shouyin-todayOrder')">
						<div class="box2Num">{{orderCount.orderCount}}</div>
						<div class="box2Text">今日订单<img class="box2Img" src="../../../assets/img/layout/bangzhu.png"></div>
					</div>
					<div class="box2Item" :class="module==='跟进'?'active':''" @click="changeModule('跟进')"  v-if="$buttonAuthority('mendian-shouyin-followUser')">
						<div class="box2Num">{{orderCount.taskCount}}</div>
						<div class="box2Text">待跟进客户<img class="box2Img" src="../../../assets/img/layout/bangzhu.png"></div>
					</div>
					<div class="box2Item" :class="module==='预约'?'active':''" @click="changeModule('预约')" v-if="$buttonAuthority('mendian-shouyin-reserve')">
						<div class="box2Num">{{orderCount.reserveCount}}</div>
						<div class="box2Text">待受理预约<img class="box2Img" src="../../../assets/img/layout/bangzhu.png"></div>
					</div>
					<div class="box2Item" :class="module==='复购'?'active':''" @click="changeModule('复购')" v-if="$buttonAuthority('mendian-shouyin-againbuy')">
						<div class="box2Num">{{orderCount.buyBackCount}}</div>
						<div class="box2Text">复购提醒<img class="box2Img" src="../../../assets/img/layout/bangzhu.png"></div>
					</div>
				</div>
			</div>
			<div class="shouyinContent">
				<jinriPanel v-if="module==='今日'"></jinriPanel>
				<genjinPanel v-if="module==='跟进'"></genjinPanel>
				<yuyuePanel v-if="module==='预约'"></yuyuePanel>
				<yuePanel v-if="module==='余额'"></yuePanel>
				<xizaokaPanel v-if="module==='洗澡卡'"></xizaokaPanel>
				<youhuiquanPanel v-if="module==='优惠券'"></youhuiquanPanel>
				<jifenPanel v-if="module==='积分'"></jifenPanel>
				<shouyinPanel v-if="module==='消费'"></shouyinPanel>
				<huodongPanel v-if="module==='活动'"></huodongPanel>
				<huifangPanel v-if="module==='回访'"></huifangPanel>
				<buyBackPanel v-if="module==='复购'"></buyBackPanel>
			</div>
		</div>
		<div v-if="dialogSearchCode_state">
			<dialogSearchCode @submit="submit_dialogSearchCode"></dialogSearchCode>
		</div>
		<div v-if="dialogRecharge_state">
			<dialogRecharge :userInfo="userInfo" @submit="submit_dialogRecharge"></dialogRecharge>
		</div>
	</div>
</template>
<script>
	import dialogSearchCode from "./dialog/dialogSearchCode.vue"
	import dialogRecharge from "../../../components/dialogRecharge.vue"
	
	import userPanel from "./userPanel.vue"
	import jinriPanel from "./jinriPanel.vue"
	import genjinPanel from "./genjinPanel.vue"
	import yuyuePanel from "./yuyuePanel.vue"
	import yuePanel from "./yuePanel.vue"
	import xizaokaPanel from "./xizaokaPanel.vue"
	import youhuiquanPanel from "./youhuiquanPanel.vue"
	import jifenPanel from "./jifenPanel.vue"
	import shouyinPanel from "./shouyinPanel.vue"
	import huodongPanel from "./huodongPanel.vue"
	import huifangPanel from "./huifangPanel.vue"
	import buyBackPanel from "./buyBackPanel.vue"
	export default {
		components:{dialogSearchCode,dialogRecharge,userPanel,jinriPanel,genjinPanel,yuyuePanel,yuePanel,xizaokaPanel,youhuiquanPanel,jifenPanel,shouyinPanel,huodongPanel,huifangPanel,buyBackPanel},
		data() {
			return {
				// 查询条码
				dialogSearchCode_state:false,
				// 会员充值
				dialogRecharge_state:false,
				
			}
		},
		computed:{
			module(){
				return this.$store.state.mendian.module
			},
			userInfo(){
				return this.$store.state.mendian.userInfo
			},
			orderCount(){
				return this.$store.state.mendian.orderCount
			},
		},
		mounted(){
			this.$store.dispatch("mendian/getCount")
		},
		methods:{
			changeModule(str){
				if(str==='abc'){
					this.dialogSearchCode_state = true;
				}else if(str==='def'){
					this.dialogRecharge_state = true;
				}else{
					this.$store.commit("mendian/setModule",str)
				}
			},
			// 查询条码
			submit_dialogSearchCode(){
				this.dialogSearchCode_state = false;
			},
			// 会员充值
			submit_dialogRecharge(){
				this.dialogRecharge_state = false;
			},
			
		}
	};
</script>
<style lang="scss" scoped>
	.page{
		width:100%;height: 100%;display: flex;
		.shouyinL{
			width:370px;height: 100%;flex-shrink: 0;margin: 0 16px 0 0;
		}
		.shouyinR{
			width:calc(100% - 386px);height:100%;
			.shouyinRBox1{
				width:425px;border-radius: 20px;background-color: #fff;display: flex;justify-content: space-around;flex-shrink: 0;margin-right: 16px;height: 93px;
				.box1Item{
					text-align: center;color:$fontColor;cursor: pointer;
					&.active{
						color:$mainColor;
					}
					.box1Img{
						width:49px;height: 48px;margin-top:12px;
					}
					.box1Text{
						font-size: 14px;margin-top:0px;
					}
				}
			}
			.shouyinRBox2{
				width:100%;display: flex;justify-content: space-around;border-radius: 20px;background-color: #fff;height: 93px;
				.box2Item{
					text-align: center;cursor: pointer;
					&.active{
						.box2Text{
							color:$mainColor;
						}
					}
					.box2Num{
						font-size: 20px;color:$mainColor;margin-top:22px;
					}
					.box2Text{
						font-size: 14px;color:$fontColor;margin-top:15px;
						.box2Img{
							width:14px;height: 14px;margin-left: 4px;vertical-align: middle;
						}
					}
				}
			}
			.shouyinContent{
				width:100%;height: calc(100% - 110px);background-color: #fff;border-radius: 20px;margin-top:16px;overflow: hidden;
			}
		}
	}
</style>
