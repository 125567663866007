<template>
	<div class="settlement">
		<div class="settlementL">
			<span class="label">总计:</span>
			<span class="value">￥{{money.total}}</span>
			<span class="label">优惠:</span>
			<span v-if="money.total===money.need" class="value reduce">￥{{money.reduce}}</span>
			<el-tooltip v-else effect="dark" placement="top">
				<div slot="content">
					<div v-if="!$public.isNull(coupon.id)">
						优惠券：{{coupon.couponName}}
						<el-button type="text" @click="delCoupon">删除</el-button>
					</div>
				</div>
				<span class="value reduce">￥{{money.reduce}}</span>
			</el-tooltip>
			
		</div>
		<div class="settlementR">
			<span class="label">应付:</span>
			<span class="value">￥{{money.need}}</span>
			<el-button v-if="$public.isNull(coupon.id)" type="infor" style="margin-left:16px;" @click="openCoupon">优惠券</el-button>
			<el-button type="infor" style="margin-left:16px;" @click="openRemark">备注</el-button>
			<el-button type="infor" style="margin-left:16px;" @click="openGua">挂单</el-button>
			<el-button type="infor" style="margin-left:16px;" @click="openJie">解单</el-button>
			<el-button type="primary" style="margin:0 16px;" @click="submit">结算</el-button>
			<div v-if="dialogSettlement_state">
				<dialogSettlement :type="'order'" :userId="userInfo.id" :params="settlementParams" @submit="submit_dialogSettlement"></dialogSettlement>
			</div>
			<div v-if="dialogRemark_state">
				<dialogRemark :remark="remark" @submit="submit_dialogRemark"></dialogRemark>
			</div>
			<div v-if="dialogJiedan_state">
				<dialogJiedan @submit="submit_dialogJiedan"></dialogJiedan>
			</div>
			<div v-if="dialogGuadan_state">
				<dialogGuadan :userInfo="userInfo" :tableData="tableData" :remark="remark" :coupon="coupon" @submit="submit_dialogGuadan"></dialogGuadan>
			</div>
			<div v-if="dialogSelectYouhuiquan_state">
				<dialogSelectYouhuiquan :userInfo="userInfo" :money="money.need" @submit="submit_dialogSelectYouhuiquan"></dialogSelectYouhuiquan>
			</div>
			<div v-if="dialogBuyBack_state">
				<dialogBuyBack :orderObj="curOrder" @submit="submit_dialogBuyBack"></dialogBuyBack>
			</div>
		</div>
	</div>
</template>
<script>
	import dialogSettlement from "../../../../components/dialogSettlement.vue"
	import dialogRemark from "../dialog/dialogRemark.vue"
	import dialogGuadan from "../dialog/dialogGuadan.vue"
	import dialogJiedan from "../dialog/dialogJiedan.vue"
	import dialogSelectYouhuiquan from "../dialog/dialogSelectYouhuiquan.vue"
	import dialogBuyBack from "../../../../components/dialogBuyBack.vue"
	export default {
		components:{dialogSettlement,dialogRemark,dialogJiedan,dialogGuadan,dialogSelectYouhuiquan,dialogBuyBack},
		data() {
			return {
				// 优惠券
				dialogSelectYouhuiquan_state:false,
				coupon:{},
				// 结算
				dialogSettlement_state:false,
				settlementParams:{params:{},money:{}},
				// 备注
				dialogRemark_state:false,
				remark:'',
				// 挂单
				dialogGuadan_state:false,
				// 解单
				dialogJiedan_state:false,
				// 复购
				dialogBuyBack_state:false,
				curOrder:{},
				
			}
		},
		computed:{
			money(){
				let money = {total:0,need:0,reduce:0}
				for(let i=0;i<this.tableData.length;i++){
					money.total = this.$public.jia(money.total,this.$public.cheng(this.tableData[i].num, this.tableData[i].originalPrice))
					money.need = this.$public.jia(money.need,this.$public.cheng(this.tableData[i].num, this.tableData[i].price))
				}
				if(!this.$public.isNull(this.coupon.id)){
					let ruleInfo = this.coupon.ruleInfo;
					if(ruleInfo.couponType.id==='1'){
						// if(ruleInfo.full)
						money.need = this.$public.jian(money.need,ruleInfo.deduct)
					}else if(ruleInfo.couponType.id==='2'){
						money.need = this.$public.jian(money.need,ruleInfo.immediateDeduct)
					}
					if(money.need<0){
						money.need = 0
					}
				}
				money.reduce = this.$public.jian(money.total,money.need)
				return money
			},
			userInfo(){
				return this.$store.state.mendian.userInfo
			},
			searchType(){
				return this.$store.state.mendian.searchType
			},
		},
		props:{
			tableData:{
				default:()=>{
					return []
				}
			},
			
		},
		mounted(){
			// 获取 备注 和 优惠券
			this.getShoppingCart()
		},
		methods:{
			// 结算
			submit(){
				if(this.searchType==='vip'&&this.$public.isNull(this.userInfo.id)){
					this.$message({
						message: '请选择会员',
						type: 'error'
					})
					return 
				}
				if(this.$public.isNull(this.tableData)){
					this.$message({
						message: '请添加产品项目',
						type: 'error'
					})
					return 
				}
				let productList = []
				for(let i=0;i<this.tableData.length;i++){
					let obj = this.tableData[i]
					console.log(obj,'/////////////pp');
					let item = {
						"productId": obj.productId,
						"productName": obj.productName,
						"productType": obj.productType,
						"petId": obj.petId,
						"petName": obj.petName,
						"brand": obj.brand,
						"firstCategoryId": obj.firstCategoryId,
						"firstCategoryName": obj.firstCategoryName,
						"secondCategoryId": obj.secondCategoryId,
						"secondCategoryName":obj.secondCategoryName,
						"modelName":obj.modelValue,
						"unit": obj.unit,
						"costPrice":obj.currentCostPrice,
						"originalPrice":obj.originalPrice,
						"price": obj.price,
						"num": obj.num,
						"activityId": obj.activityId,
						"activityName": obj.activityName,
						"activityType": obj.activityType,
						"activityPrice":obj.activityPrice,
						"payType": obj.payType,
						"remark": obj.remark,
						"isGive": obj.isGive,
						"buyBack": obj.buyBack,
						"salesList": obj.salesList,  // 销售人员
						"productDetails": [],
					}
					if(this.$public.isNull(obj.salesList)){
						this.$message({
							message: '请完善销售人员',
							type: 'error'
						})
						return 
					}
					let productDetails = []
					for(let a=0;a<obj.productDetails.length;a++){
						productDetails.push({
							"productType": obj.productDetails[a].productType,
							"productId": obj.productDetails[a].productId,
							"productName": obj.productDetails[a].productName,
							"num": obj.productDetails[a].num,
							"price": obj.productDetails[a].price,
							"originalPrice": obj.productDetails[a].originalPrice,
							"unit": obj.productDetails[a].unit,
							"modelName": obj.productDetails[a].modelName,
							"brand": obj.productDetails[a].brand,
							"firstCategoryId": obj.productDetails[a].firstCategoryId,
							"firstCategoryName": obj.productDetails[a].firstCategoryName,
							"secondCategoryId":obj.productDetails[a].secondCategoryId,
							"secondCategoryName": obj.productDetails[a].secondCategoryName,
						})
					}
					item.productDetails = productDetails
					productList.push(item)
				}
				let params = {
					"orderTypeId": 1, // 1. 商品 2.洗美 3.寄养 4. 虚拟类
					"memberId": this.userInfo.id||'',
					"memberName": this.$public.isNull(this.userInfo.id)?'散客':this.userInfo.basicInfo.nickName,
					"memberMobile": this.userInfo.basicInfo.userMobile||'',
					"oneSelf": false, // 是否自用
					"remark": this.remark,  // 备注
					"couponNo": this.$public.isNull(this.coupon.id)?'':this.coupon.no,  // 优惠券编号
					"productList": productList,
				}
				this.settlementParams = {
					params:params,
					money:this.money,
				}
				this.dialogSettlement_state = true;
				
			},
			// 支付
			submit_dialogSettlement(obj){
				this.dialogSettlement_state = false;
				if(obj.type==='success'){
					this.$message({
						message: '操作成功',
						type: 'success'
					});
					this.$store.dispatch("mendian/getCount") // 获取订单数
					this.$emit("submit",{type:'jiesuan_success'})
					this.curOrder = obj.data
					if(this.curOrder.buyBackRemind===2&&!this.$public.isNull(this.curOrder.userInfo.userId)){
						this.dialogBuyBack_state = true;
					}
				}
			},
			// 复购
			submit_dialogBuyBack(){
				this.dialogBuyBack_state = false;
			},
			// 挂单
			openGua(){
				if(this.searchType==='vip'&&this.$public.isNull(this.userInfo.id)){
					this.$message({
						message: '请选择会员',
						type: 'error'
					})
					return 
				}
				if(this.$public.isNull(this.tableData)){
					this.$message({
						message: '请添加消费项目',
						type: 'error'
					})
					return 
				}
				this.dialogGuadan_state = true;
			},
			submit_dialogGuadan(obj){
				this.dialogGuadan_state = false;
				if(obj.type==="success"){
					this.$message({
						message: '挂单成功',
						type: 'success'
					})
					this.coupon = {}
					this.remark = ''
					this.$emit("submit",{type:'guadan_success'})
				}
			},
			// 解单
			openJie(){
				this.dialogJiedan_state=true;
			},
			submit_dialogJiedan(obj){
				this.dialogJiedan_state = false;
				if(obj.type==="success"){
					this.$emit("submit",{type:'jiedan_success',data:obj.data})
				}
			},
			// 优惠券
			openCoupon(){
				if(this.searchType==='vip'&&this.$public.isNull(this.userInfo.id)){
					this.$message({
						message: '请选择会员',
						type: 'error'
					})
					return 
				}
				if(this.$public.isNull(this.tableData)){
					this.$message({
						message: '请添加消费项目',
						type: 'error'
					})
					return 
				}
				for(let i=0;i<this.tableData.length;i++){
					if(!this.$public.isNull(this.tableData[i].activityId)){
						this.$message({
							message: '选择活动后不可再使用优惠券',
							type: 'error'
						})
						return 
					}
				}
				this.dialogSelectYouhuiquan_state=true
			},
			submit_dialogSelectYouhuiquan(obj){
				this.dialogSelectYouhuiquan_state = false
				if(obj.type==="success"){
					this.coupon = obj.data;
					this.$emit("submit",{type:'youhuiquan_success',data:this.coupon})
				}
			},
			delCoupon(){
				this.coupon = {}
				this.$emit("submit",{type:'youhuiquan_success',data:{}})
			},
			// 备注
			openRemark(){
				if(this.searchType==='vip'&&this.$public.isNull(this.userInfo.id)){
					this.$message({
						message: '请选择会员',
						type: 'error'
					})
					return 
				}
				this.dialogRemark_state = true;
			},
			submit_dialogRemark(obj){
				this.dialogRemark_state=false;
				if(obj.type==="success"){
					this.remark = obj.data
					this.changeShoppingCart()
				}
			},
			// 购物车
			changeShoppingCart(){
				let otherParam = {
					remark:this.remark,
					// coupon:this.coupon,
				}
				let params = {
					"memberId":this.userInfo.id,
					"memberName": this.userInfo.basicInfo.nickName,
					"memberMobile":  this.userInfo.basicInfo.userMobile,
					"productIntro":null,
					"otherParam": JSON.stringify(otherParam),
				}
				this.$http.post("/business/ShoppingCart/Insert",params)
			},
			getShoppingCart(){
				if(this.$public.isNull(this.userInfo.id)){
					return
				}
				let params = {
					memberId:this.userInfo.id,
				}
				this.$http.get("/business/ShoppingCart/Info",params).then(res=>{
					if(res.code===0){
						if(res.data){
							if(!this.$public.isNull(res.data.otherParam)){
								let otherParam = JSON.parse(res.data.otherParam)
								this.remark = otherParam.remark
								// this.coupon = otherParam.coupon
							}
						}
					}
				})
			},
		}
	};
</script>
<style lang="scss" scoped>
	.settlement{
		width:100%;height: 72px;border-radius: 20px;box-shadow: 0px -1px 15px 0px rgba(0,0,0,.13);display: flex;justify-content: space-between;padding:16px 0;box-sizing: border-box;
		.settlementL{
			line-height: 40px;
			.label{
				font-size: 14px;color:$fontColor;margin-left:20px;
			}
			.value{
				font-size: 20px;color:$fontColor;
				&.reduce{
					color:$mainColor;
				}
			}
		}
		.settlementR{
			line-height: 40px;
			.label{
				font-size: 14px;color:$fontColor;margin-left:20px;
			}
			.value{
				font-size: 20px;color:#FF3939;
			}
		}
		
	}
</style>
